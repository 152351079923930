import React from "react";

import {
  useDisclosure,
  Button,
  FormControl,
  FormLabel,
  Input,
  Modal,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalOverlay,
  Select,
  Stack,
  ThemeProvider,
} from "@chakra-ui/core";

export function ChakraAddABook(props) {
  const { isOpen, onOpen, onClose } = useDisclosure();

  return (
    <ThemeProvider>
      <h1>Add a Book</h1>
      <Button onClick={onOpen}>Open Modal</Button>
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Add a Book</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Stack spacing={4}>
              <FormControl>
                <FormLabel>Author</FormLabel>
                <Select placeholder="Choose an author...">
                  <option value="Adams, Douglas">Adams, Douglas</option>
                  <option value="Asimov, Isaac">Asimov, Isaac</option>
                  <option value="Augustine">Augustine</option>
                  <option value="Austen, Jane">Austen, Jane</option>
                </Select>
              </FormControl>
              <FormControl>
                <FormLabel>Title</FormLabel>
                <Input />
              </FormControl>
              <FormControl>
                <FormLabel>Pages</FormLabel>
                <Input type="number" />
              </FormControl>
              <FormControl>
                <Select placeholder="Bookmarked">
                  <option value="Bookmarked">Bookmarked</option>
                  <option value="Finished">Finished</option>
                  <option value="To Read">To Read</option>
                </Select>
              </FormControl>
            </Stack>
          </ModalBody>

          <ModalFooter>
            <Button variantColor="blue" mr={3} onClick={onClose}>
              Add
            </Button>
            <Button variant="outline">Cancel</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </ThemeProvider>
  );
}
