import React, { useState } from "react";

import {
  Column,
  Form,
  FormGroup,
  Grid,
  Row,
  Header,
  HeaderMenuButton,
  HeaderMenuItem,
  HeaderName,
  HeaderNavigation,
  HeaderSideNavItems,
  ModalWrapper,
  NumberInput,
  Select,
  SelectItem,
  SideNav,
  SideNavItems,
  SideNavMenuItem,
  TextInput,
} from "carbon-components-react";
import { Notebook32 } from "@carbon/icons-react";
import "carbon-components/css/carbon-components.min.css";

export function CarbonAddABook(props) {
  const [isSideNavExpanded, setIsSideNavExpanded] = useState(false);

  return (
    <>
      <Header>
        <HeaderMenuButton
          aria-label="Open menu"
          onClick={() => setIsSideNavExpanded(!isSideNavExpanded)}
          isActive={isSideNavExpanded}
        />
        <HeaderName prefix="Backstage">Reading</HeaderName>
        <HeaderNavigation aria-label="Backstage Reading">
          <HeaderMenuItem href="/chakra">Chakra</HeaderMenuItem>
          <HeaderMenuItem isCurrentPage href="/chakra">
            Reading
          </HeaderMenuItem>
        </HeaderNavigation>
        <SideNav
          aria-label="Side navigation"
          expanded={isSideNavExpanded}
          isPersistent={false}
        >
          <SideNavItems>
            <HeaderSideNavItems>
              <SideNavMenuItem href="/chakra">Chakra</SideNavMenuItem>
              <SideNavMenuItem
                isCurrentPage
                href="/chakra"
                renderIcon={Notebook32}
              >
                Reading
              </SideNavMenuItem>
            </HeaderSideNavItems>
          </SideNavItems>
        </SideNav>
      </Header>

      <div className="shellContent" style={{ padding: "0.5rem" }}>
        <h2>Reading</h2>

        <ModalWrapper
          buttonTriggerText="Add a Book"
          modalHeading="Add a Book"
          modalLabel="Reading"
        >
          <Form>
            <Grid>
              <Row>
                <Column sm={4}>
                  <FormGroup>
                    <Select
                      labelText="Author"
                      placeholder="Choose an author..."
                    >
                      <SelectItem text="Adams, Douglas" />
                      <SelectItem text="Asimov, Isaac" />
                      <SelectItem text="Augustine" />
                      <SelectItem text="Austen, Jane" />
                    </Select>
                  </FormGroup>
                </Column>
              </Row>

              <Row>
                <Column sm={4}>
                  <FormGroup>
                    <TextInput labelText="Title" />
                  </FormGroup>
                </Column>
                <Column sm={1}>
                  <FormGroup>
                    <NumberInput label="Pages" min={1} />
                  </FormGroup>
                </Column>
              </Row>
              <Row>
                <Column sm={1}>
                  <FormGroup>
                    <Select labelText="Status" placeholder="Bookmarked">
                      <SelectItem text="Bookmarked" />
                      <SelectItem text="Finished" />
                      <SelectItem text="To Read" />
                    </Select>
                  </FormGroup>
                </Column>
              </Row>
            </Grid>
          </Form>
        </ModalWrapper>
      </div>
    </>
  );
}
