import React from "react";
// import { useAuth0 } from "../react-auth0-spa";
import { BrowserRouter as Router, Link, Route } from "react-router-dom";

import { CarbonAddABook } from "./CarbonAddABook";
import { ChakraAddABook } from "./ChakraAddABook";

export default function LoggedIn() {
  // const { user } = useAuth0();

  return (
    <Router>
      <button>
        <Link to="/carbon">Carbon</Link>
      </button>
      <button>
        <Link to="/chakra">Chakra</Link>
      </button>
      <hr />
      <Route path="/carbon">
        <CarbonAddABook />
      </Route>
      <Route path="/chakra">
        <ChakraAddABook />
      </Route>
    </Router>
  );
}
