import React from "react";
import { useAuth0 } from "./react-auth0-spa";

import LoggedIn from "./components/LoggedIn";
import LoggedOut from "./components/LoggedOut";

function App() {
  const { isAuthenticated, loading } = useAuth0();

  if (loading) {
    return <div>Loading...</div>;
  }

  return isAuthenticated ? <LoggedIn /> : <LoggedOut />;
}

export default App;
